import {doQuery, doUpload} from '@/api/core'
import { addRequestReward } from '@/api/consult'
import {mapActions, mapGetters} from 'vuex'

export default {
    name: "publishQues",
    data() {
        return {
            questionTypes:[],
            questionTypeIndex: null,

            helpTips: '请详细、完整的描述您的问题，避免随意发布提问，妥善使用提问次数（15~500字）提问举例：我去年有一个房屋出租，产生的收入是否需要在今年的个人所得税汇算清缴时进行社保？应该怎样申报？有什么政策依据？',
            content: null,
            consultDialogVisible: false,

            imgAdressArray: [],
            imgHover:[],
            previewImageUrl: null,
            previewImgVisible: false,
        }
    },
    computed: {
        ...mapGetters([
            'winSize',
            'staffId',
            'myInfo',
            'qToken',
            'mobilephone'
        ])
    },
    mounted() {
        if(this.mobilephone && !this.qToken) {
            this.QLogin(this.mobilephone)
        }
        this.refreshQuesTypes()
    },
    methods: {
        ...mapActions([
            'QLogin'
        ]),

        quesRewardSuccessByAI() {
            const that = this
            if(!that.$cmyUtils.trim(that.content)) {
                that.$message.error('问题内容不可为空')
                return
            }
            if(that.$cmyUtils.trim(that.content).length < 5 || that.$cmyUtils.trim(that.content).length > 500) {
                that.$message.error('长度需要在5-500字')
                return
            }
            that.$router.push({
                name: 'StudentManualConsult',
                params: {
                    content: that.$cmyUtils.trim(that.content),
                    img1: that.imgAdressArray.length > 0 ? that.imgAdressArray[0] : '',
                    img2: that.imgAdressArray.length > 1 ? that.imgAdressArray[1] : '',
                    img3: that.imgAdressArray.length > 2 ? that.imgAdressArray[2] : '',
                }
            })
        },

        publicMyQuestion() {
            const that = this
            if(that.questionTypeIndex == null) {
                that.$message.error('请选择一个问题类型')
                return
            }
            if(!that.$cmyUtils.trim(that.content)) {
                that.$message.error('问题内容不可为空')
                return
            }
            if(that.content.length < 5 || that.content.length > 500) {
                that.$message.error('长度需要在5-500字')
                return
            }
            that.quesRewardSuccessByPay()
        },

        quesRewardSuccessByPay() {
            const that = this
            var data = {
                content: that.content,
                award_fee: 0,
                award_prodid: 'C22A',
                pay_mode: 0,
                coupon_no: '',
                requesttype_code: that.questionTypes[that.questionTypeIndex].kdbtype_code,
                img1: that.imgAdressArray.length > 0 ? that.imgAdressArray[0] : '',
                img2: that.imgAdressArray.length > 1 ? that.imgAdressArray[1] : '',
                img3: that.imgAdressArray.length > 2 ? that.imgAdressArray[2] : '',
            }
            var hide = that.$message.loading('请稍后...',0)
            addRequestReward(data)
                .then(res1 => {
                    if (res1.errcode >= 0) {
                        /*支付金额为0 或者 返回需要支付的金额为0*/
                        if (data.award_fee <= 0 || res1.pay_total <= 0) {
                            this.$message.success('问题发布成功，即将前往对话...')
                            setTimeout(() => {
                                that.$router.push({name: 'StudentManualConsult', params: { request_id: res1.request_id}})
                            },200)

                        }else {
                            // that.doPay()
                        }
                    }
                })
                .finally(() => {
                    hide()
                })
        },

        showImgShadow(index,boShow) {
            const that = this
            that.$set(that.imgHover,index,boShow)
        },
        showImg(index) {
            const that = this
            that.previewImageUrl = that.imgAdressArray[index]
            that.previewImgVisible = true
        },
        removeImg(index) {
            const that = this
            const arr1 = [...that.imgAdressArray]
            const arr2 = [...that.imgHover]
            arr1. splice(index,1 )
            arr2. splice(index,1 )
            that.imgAdressArray = arr1
            that.imgHover = arr2
        },
        refreshQuesTypes() {
            const that = this
            doQuery({
                query_code: 'SL200',
                page: 1,
                limit: 1
            }).then(res => {
                if(res.errcode >= 0 && res.data && res.data.length > 0) {
                    res.data.forEach(item => {
                        switch (item.kdbtype) {
                            case '财务会计':
                                item.icon_name = 'icon-caiwu1'
                                break
                            case '涉税政策':
                                item.icon_name = 'icon-huaban50fuben2'
                                break
                            case '办税实务':
                                item.icon_name = 'icon-shui'
                                break
                            case '风险管理':
                                item.icon_name = 'icon-fengxianbeifen'
                                break
                            case '软件操作':
                                item.icon_name = 'icon-shubiao'
                                break
                            case '海关/进出口':
                                item.icon_name = 'icon-jinchukouguanli'
                                break
                            case '社保人资':
                                item.icon_name = 'icon-shebao'
                                break
                            case '工商问题':
                                item.icon_name = 'icon-gongshang1'
                                break
                            case '纳税筹划':
                                item.icon_name = 'icon-chouhuafangan'
                                break
                            case '其他类型':
                                item.icon_name = 'icon-qita'
                                break
                        }
                    })
                    that.questionTypes = res.data
                }
            })
        },

        startConsult(index = null) {
            const that = this
            that.consultDialogVisible = true
            that.questionTypeIndex = index

        },

        handleCancel() {
            const that = this
            that.consultDialogVisible = false
            that.questionTypeIndex = null
            that.imgAdressArray = []
            that.imgHover = []
        },

        toMyConsultLog({ key }) {
            const that = this
            that.$router.push({name: key})
        },

        toRobotConsult() {
            const that = this
            that.$router.push({name: 'StudentAIConsult', params: {query: that.content}})
        },

        doUploadImg(data) {
            const that = this
            if(that.imgAdressArray.length == 3) {
                that.$message.error('上传图片太多啦')
                return
            }
            var hide = that.$message.loading('上传中...',0)
            doUpload(that.$consts.url.UPLOAD_IMG, data.file)
                .then(res => {
                    if (res) {
                        that.$message.success('操作成功' ,2)
                        that.imgAdressArray.push(res)
                    }else {
                        that.$message.error(that.$consts.biz.FAIL_MSG, 2)
                    }
                })
                .finally(() => {
                    hide()
                })
        },
    }
}
